import { Pagination } from '@/api/pagination';
import { of, Observable, async } from 'rxjs';
import { map } from 'rxjs/operators';
import { AcompanhamentoAPI } from '@/api/acompanhamento.api';
import { PageAcompanhamentoInput, Acompanhamento } from '@/models/acompanhamento';

const api = new AcompanhamentoAPI();

const page = (input: PageAcompanhamentoInput): Observable<Pagination<Acompanhamento>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageAccompaniments: { items, totalItems } } = data;
    return { items, totalItems }
  }));
}

const sendToARCE = (pedidoId: number): Observable<Acompanhamento> => {
  return api.sendToARCE(pedidoId).pipe(map((data: any) => ({...data})))
}


export default {
  page,
  sendToARCE
}
