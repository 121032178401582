import PedidoService from "@/services/pedidos.service";
import {
	Pedido,
	PagePedidoInput,
	PayloadUpdateStatusPedido,
} from "@/models/pedido";
import { Pagination } from "@/api/pagination";
import GlobalStore from "@/store/modules/global";
import { router } from "@/router";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DocumentoService from "@/services/documento.service";
import {
	Documento,
	PayloadUpdateStatusDocumento,
	PayloadUpdateInfosDocumento,
} from "@/models/documento";
import acompanhamentoService from "@/services/acompanhamento.service";
import { PageTipoDocumentoInput } from "@/models/tipo-documento";
import tipoDocumentoService from "@/services/tipo-documento.service";

import axios from "axios";
import { BACK_URL_SERVICE } from "@/commons/config";
const api = axios.create({ baseURL: BACK_URL_SERVICE });

const prepareTablePaginate = (paginate: any) => {
	const { input } = paginate

	const createdAtParsed = input.createdAt.map((v: any) => {
		const date = v.split('/')

		return `${date[2]}-${date[1]}-${date[0]}`
	})

	const inspection_created_at_parsed = input.inspection_created_at.map((v: any) => {
		const date = v.split('/')

		return `${date[2]}-${date[1]}-${date[0]}`
	})

	const inputParsed = {...input, createdAt: createdAtParsed, inspection_created_at: inspection_created_at_parsed }

	return {...paginate, input: inputParsed }
}

export const pedido = {
	namespaced: true,
	state: {
		table: {
			header: [
				{
					text: "Número",
					align: "start",
					sortable: true,
					value: "id",
				},
				{
					text: "Grupo",
					align: "start",
					sortable: true,
					value: "group",
				},
				{
					text: "Número ARCE",
					align: "start",
					sortable: true,
					value: "numeroPedidoArce",
				},
				{
					text: "Empresa",
					align: "start",
					sortable: true,
					value: "company.name",
				},
				{
					text: "Veículo",
					align: "start",
					sortable: true,
					value: "placa",
				},
				{
					text: "Tipo",
					align: "start",
					sortable: true,
					value: "typeSolicitation.name",
				},
				{
					text: "Status Pedido",
					align: "start",
					sortable: true,
					value: "status",
				},
				{
					text: "Status Pagamento",
					align: "start",
					sortable: true,
					value: "payments",
				},
				{
					text: "Status Autovistoria",
					align: "start",
					sortable: true,
					value: "inspections",
				},
				{
					text: "Data do Pedido",
					align: "start",
					sortable: true,
					value: "createdAt",
				},
				{
					text: "Data da Autovistoria",
					align: "start",
					sortable: true,
					value: "inspectionCreatedAt",
				},
				{
					text: "Ações",
					align: "start",
					sortable: false,
					value: "actions",
				},
			],
			content: [] as Array<Pedido>,
			paginate: {
				input: {
					type: null as null,
					status: null as null,
					placa: null as null,
					company: null as null,
					statusPayment: null as null,
					statuses: null as [],
					group: null as null,
					inspection_statuses: null as null,
					inspection_created_at: [] as [],
					createdAt: [] as []
				},
				inputPage: {
					page: 1,
					size: 10 as any,
					order: "id",
					descingOrder: true,
				},
			},
			isLoading: false,
			total: 0,
		},
		form: {
			isLoading: false,
			currentStep: 1,
			pedido: {
				typeSolicitation: null as string,
				veiculoTransportadoraId: null as string,
				placa: null as string,
			},
			documents: [
				{
					file: null as File,
					type: "REQUERIMENTO_ASSINADO_RECONHECIDO_FIRMA",
					dateOfIssue: "",
					dateExpiration: "",
					agencyShipping: "",
					code: "",
				},
				{
					file: null as File,
					type: "COPIA_CRLV",
					dateOfIssue: "",
					dateExpiration: "",
					agencyShipping: "",
					code: "",
				},
				{
					file: null as File,
					type: "APOLICE_SEGURO_ASSINADA_CARIMBADA_SEGURADORA",
					dateOfIssue: "",
					dateExpiration: "",
					agencyShipping: "",
					code: "",
				},
				{
					file: null as File,
					type: "LAUDO_TECNICO_E_ART",
					dateOfIssue: "",
					dateExpiration: "",
					agencyShipping: "",
					code: "",
				},
			],
			payment: {
				type: null as string,
				data: null as Object,
			},
		},
		detail: {
			data: null as Pedido,
			isLoading: false,
		},
		autovistoria: {
			table: [] as any,
			tableIsLoading: false,
			desabiliteFields: '' as any
		}
	},
	mutations: {
		setTableIsLoading(state: any, value: boolean): void {
			state.table.isLoading = value;
		},
		setTableContent(state: any, value: Pedido[]): void {
			state.table.content = value;
		},
		setTableTotal(state: any, value: number): void {
			state.table.total = value;
		},
		setTablePaginate(state: any, value: PagePedidoInput): void {
			state.table.paginate = value;
		},
		setFormIsLoading(state: any, value: boolean): void {
			state.form.isLoading = value;
		},
		setFormPedido(state: any, value: object): void {
			state.form.pedido = { ...value };
		},
		setFormCurrentStep(state: any, value: number): void {
			state.form.currentStep = value;
		},
		setFormDocumentos(state: any, value: Array<object>): void {
			state.form.documents = value;
		},
		setDetail(state: any, value: Pedido): void {
			state.detail.data = value;
		},
		setDetailIsLoading(state: any, value: boolean): void {
			state.detail.isLoading = value;
		},
		SET_DOCUMENTS_DETAIL: (state: any, docs: Array<any>) => {
			state.detail.data.documents = docs;
		},
		SET_DOCUMENT_FORM: (state: any, { typeDocumentKey, doc }: any) => {
			const docs = state.form.documents.map((d: any) => {
				return d.typeDocumentKey === typeDocumentKey ? { ...d, ...doc } : d;
			});
			state.form.documents = docs;
		},
		SET_PAYMENTS_GROUP: (state: any, payments: Array<any>) => {
			state.detail.data.payments = payments;
		},
		SET_AUTOVISTORIAS: (state: any, autovistorias: Array<any>) => {
			state.autovistoria.table = autovistorias;
		},
		SET_AUTOVISTORIAS_TEXT_FIELDS: (state: any, value: []) => {
			const verifyStatus = value.some((a: any) => a.status.original_value == 'awaiting_engineer_analise');
			state.autovistoria.desabiliteFields = verifyStatus;
		},
		setAutovistoriaTableIsLoading: (state: any, value: Boolean) => {
			state.autovistoria.tableIsLoading = value
		},
	},
	actions: {
		SEARCH({ commit, dispatch, state }: any): void {
			commit("setTableIsLoading", true);

			const paginage = prepareTablePaginate(state.table.paginate)

			PedidoService.page(paginage).subscribe(
				({ items, totalItems }) => {
					commit("setTableContent", items);
					commit("setTableTotal", totalItems);
					commit("setTableIsLoading", false);
				},
				(error) => {
					commit("setTableIsLoading", false);
				}
			);
		},
		CHANGE_PAGE(
			{ commit, dispatch, state }: any,
			value: PagePedidoInput
		): void {
			commit("setTablePaginate", value);
		},
		CLEAR({ commit }: any): void {
			commit("setTableContent", []);
			commit("setTableTotal", 0);
			commit("setTableIsLoading", false);
			commit("setTablePaginate", {
				input: {
					type: null as null,
					status: null as null,
					placa: null as null,
					company: null as null,
					statusPayment: null as null,
					statuses: [],
					inspection_statuses: null as null,
					inspection_created_at: [] as [],
					createdAt: [] as []
				},
				inputPage: {
					page: 1,
					size: 10,
					order: "id",
					descingOrder: true,
				},
			});
		},
		SHOW({ commit, dispatch, state }: any, value: number): void {
			commit("setDetailIsLoading", true);
			PedidoService.show(value).subscribe(
				(res) => {
					if (res) {
						commit("setDetail", res);
						commit("setDetailIsLoading", false);
						const paginateAccomp = {
							input: {
								solicitationId: res.id,
							},
							inputPage: {
								page: 1,
								size: 200,
								order: "createdAt",
								descingOrder: true,
							},
						};
						dispatch(
							"acompanhamentos/changePageAcompanhamento",
							paginateAccomp,
							{ root: true }
						);
						dispatch("acompanhamentos/pageAcompanhamentos", null, {
							root: true,
						});

						const paginateDocs = {
							input: {
								name: "",
								solicitationId: Number(res.id),
							},
							inputPage: {
								page: 1,
								size: 200,
								order: "id",
								descingOrder: true,
							},
						};
						DocumentoService.page(paginateDocs).subscribe(
							({ items }: any) => {
								commit("SET_DOCUMENTS_DETAIL", items);
							},
							(error) => {
								commit("setDetailIsLoading", false);
							}
						);
					} else {
						GlobalStore.setSnackBar({
							show: true,
							type: "warning",
							message: "Pedido não encontrado",
						});
						router.push({ name: "dashboard-pedidos-list" });
					}
				},
				(error) => {
					commit("setDetailIsLoading", false);
				}
			);
		},
		SHOW_PAYMENTS_GROUP({ commit, dispatch, state }: any, group: string): void {
			PedidoService.showPaymentsGroup(group).subscribe(
				(res: any) => {
					if (state.detail.data.status == "PAGAMENTO_PENDENTE") {
						const pendingPayment = res.filter((pay: any) => pay.parent == null );
						commit("SET_PAYMENTS_GROUP", pendingPayment);
					} else {
						const { detail:{ data: { id }} } = state
						const pendingPayment = res.filter((pay: any) => pay.parent != null && pay.solicitation_id == id);
						commit("SET_PAYMENTS_GROUP", pendingPayment);
					}

				},
				(err: any) => {
					commit("setDetailIsLoading", false);
				}
			);
		},
		CLEAR_DETAIL({ commit, dispatch, state }: any): void {
			commit("setDetail", null);
			commit("setDetailIsLoading", false);
		},
		DOWNLOAD_DOCUMENTO(
			{ commit, dispatch, state }: any,
			value: Documento
		): void {
			commit("setDetailIsLoading", true);
			DocumentoService.download(value).subscribe(
				(res) => {
					DocumentoService.doDownloadFile(res, value.name);
					commit("setDetailIsLoading", false);
				},
				(error) => {
					commit("setDetailIsLoading", false);
				}
			);
		},
		async UPDATE_STATUS(
			{ commit, dispatch, state }: any,
			{ id, status }: Pedido
		): Promise<any> {
			commit("setDetailIsLoading", true);
			try {
				const body: PayloadUpdateStatusPedido = { status: status };
				await PedidoService.updateStatus(id, body).toPromise();
				dispatch("pedido/SHOW", id, { root: true });
			} catch (error) {
				commit("setDetailIsLoading", false);
			}
		},
		async SEND_TO_ARCE(
			{ commit, dispatch, state }: any,
			{ id }: Pedido
		): Promise<any> {
			commit("setDetailIsLoading", true);
			try {
				await acompanhamentoService.sendToARCE(id).toPromise();
				dispatch("pedido/SHOW", id, { root: true });
			} catch (error) {
				commit("setDetailIsLoading", false);
			}
		},
		async UPDATE_DOCUMENTO_STATUS(
			{ commit, dispatch, state }: any,
			value: Documento
		): Promise<any> {
			commit("setDetailIsLoading", true);
			try {
				let body: PayloadUpdateStatusDocumento;

				// todo: melhorar essa implementação de checagem de status
				if (value.status === "REJEITADO") {
					body = { status: value.status, reason: value.reason };
				} else {
					body = { status: value.status };
				}

				await DocumentoService.updateStatus(value.id, body).toPromise();
				commit("setDetailIsLoading", false);
				dispatch("pedido/SHOW", state.detail.data.id, { root: true });
			} catch (error) {
				commit("setDetailIsLoading", false);
			}
		},
		async UPDATE_DOCUMENTO_INFOS(
			{ commit, dispatch, state }: any,
			value: Documento
		): Promise<any> {
			commit("setDetailIsLoading", true);
			try {
				dayjs.extend(customParseFormat).locale("pt-BR");

				const body: PayloadUpdateInfosDocumento = {
					dateOfIssue: value.dateOfIssue
						? dayjs(value.dateOfIssue, "DD/MM/YYYY").format("YYYY-MM-DD")
						: null,
					dateExpiration: value.dateExpiration
						? dayjs(value.dateExpiration, "DD/MM/YYYY").format("YYYY-MM-DD")
						: null,
					agencyShipping: value.agencyShipping,
					code: value.code,
					cityId: value.cityId,
					surveyHour: value.surveyHour,
				};

				await DocumentoService.updateInfos(value.id, body).toPromise();
				commit("setDetailIsLoading", false);
				dispatch("pedido/SHOW", state.detail.data.id, { root: true });
			} catch (error) {
				commit("setDetailIsLoading", false);
			}
		},
		findTypesDocuments: async (
			{ state, commit }: any,
			pagination: PageTipoDocumentoInput
		) => {
			commit("setDetailIsLoading", true);
			try {
				const { items } = await tipoDocumentoService
					.page(pagination)
					.toPromise();
				const docs = items.map((d) => ({
					id: null,
					typeDocument: d.id,
					typeDocumentName: d.name,
					typeDocumentDescription: d.description,
					typeDocumentKey: d.key,
					file: null,
					filename: null,
					type: null,
					size: 0,
					progress: 0,
					uploaded: false,
					error: false,
				}));
				commit("setFormDocumentos", docs);
				commit("setDetailIsLoading", false);
			} catch (error) {
				commit("setDetailIsLoading", false);
			}
		},
		uploadDocument: async (
			{ state, commit }: any,
			{ currentDocument, file }: any
		) => {
			//commit('setDetailIsLoading', true)
			try {
				const document = {
					solicitation: state.detail.data.id,
					file: file,
					filename: file.name,
					type: file.type,
					size: file.size,
					error: false,
					progress: 0,
					uploaded: false,
				};
				commit("SET_DOCUMENT_FORM", {
					typeDocumentKey: currentDocument.typeDocumentKey,
					doc: document,
				});
				const body = state.form.documents.find(
					(d: any) => d.typeDocumentKey === currentDocument.typeDocumentKey
				);

				let formData = new FormData();
				formData.append("file", body.file);
				formData.append(
					"typeDocument",
					`${
						typeof body.typeDocument === "object"
							? body.typeDocument.id
							: body.typeDocument
					}`
				);
				formData.append("solicitation", `${body.solicitation}`);
				const { data } = await api.post(`/documents`, formData, {
					headers: { "Content-Type": "multipart/form-data" },
					onUploadProgress: ({ total, loaded }: any) => {
						const percentage = Math.round((loaded * 100) / total);
						commit("SET_DOCUMENT_FORM", {
							typeDocumentKey: body.typeDocumentKey,
							doc: { progress: percentage },
						});
					},
				});
				commit("SET_DOCUMENT_FORM", {
					typeDocumentKey: currentDocument.typeDocumentKey,
					doc: { id: data.id, uploaded: true, error: false },
				});
				//commit('setDetailIsLoading', false)
			} catch (error) {
				const document = { error: true };
				commit("SET_DOCUMENT_FORM", {
					typeDocumentKey: currentDocument.typeDocumentKey,
					doc: document,
				});
				//commit('setDetailIsLoading', false)
			}
		},
		async FIND_BY_STATUS_AND_TYPE(
			{ commit, dispatch, state }: any,
			{ status, tipoPedido,inspection_statuses, inspection_created_at }: any
		): Promise<number> {
			try {
				const input = {
					...state.table.paginate.input,
					statuses: status,
					type: tipoPedido,
					inspection_statuses,
					inspection_created_at
				};
				const query = { ...state.table.paginate, input };
				const { totalItems } = await PedidoService.page(query).toPromise();
				return totalItems;
			} catch (error) {
				throw new Error(`${error}`);
			}
		},
	 	async LIST_AUTOVISTORIAS({ commit, dispatch, state }: any ){
			const { id } = state.detail.data;
			commit("setAutovistoriaTableIsLoading", true);
	 		await PedidoService.getAllAutovistoria(id).subscribe(
				(res) => {
					commit("setAutovistoriaTableIsLoading", false);
					commit("SET_AUTOVISTORIAS", res)
					commit("SET_AUTOVISTORIAS_TEXT_FIELDS", res)
				},
				(erro) => {
					commit("setAutovistoriaTableIsLoading", false);
				}
			);
		},
		clearAutovistorias({ commit, dispatch, state }: any): any {
			commit("SET_AUTOVISTORIAS", [])
		}
	},
};
