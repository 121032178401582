import Vue from 'vue'
import Vuex from 'vuex'

import { pedido } from "./modules/pedido"
import { perfil } from "./modules/perfil"
import { funcionalidade } from "./modules/funcionalidade"
import { usuario } from "./modules/usuario"
import { pagamento } from "./modules/pagamento"
import { tipoPedido } from "./modules/tipo-pedido"
import { acompanhamentos } from "./modules/acompanhamentos"
import { empresa } from "./modules/empresa"
import { autovistoria } from "./modules/autovistoria"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    pedido,
    perfil,
    funcionalidade,
    usuario,
    pagamento,
    tipoPedido,
    acompanhamentos,
    empresa,
    autovistoria
  }
})
