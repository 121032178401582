import { AxiosResponse } from "axios";
import { BACK_URL_SERVICE } from "@/commons/config";

import GlobalStore from "@/store/modules/global";
import AuthenticationService from "@/services/authentication.service";

export const handleResponse = (response: any) => {
  const { data: result, config } = response;
  if ([401, 403].find(status => status === response.status)) {
    AuthenticationService.logout();
    return Promise.reject(result);
  }

  if (config.url.includes("graphql")) {
    const { data, errors } = result;

    if (errors) {
      return handleGraphqlErrorResponse(errors, result);
    }

    return data;
  }

  return result;
};

const handleGraphqlErrorResponse = (errors: any, result: any) => {
  const { message } = errors[0];
  GlobalStore.setSnackBar({ message, show: true, type: "error" });
  if (message === "Sessão expirada, faça login novamente.") {
    GlobalStore.setBanner({
      message: "Sessão expirou",
      show: true,
      color: "warning"
    });
  }
  return Promise.reject(result);
};

export const handleErrorResponse = (error: any) => {
  const { config, response } = error;
  const { data } = response;
  let message = data.message || "Erro ao tentar acessar esse recurso";

  if (
    config.url === `${BACK_URL_SERVICE}/auth/users/login` &&
    (response.status === 401 || response.status === 400)
  ) {
    message =
      "Crendenciais inválidas, verifique as informações e tente novamente";
    GlobalStore.setSnackBar({ message, show: true, type: "error" });
  } else if (response.status === 401) {
    GlobalStore.setBanner({ message, show: true, color: "warning" });
  } else {
    GlobalStore.setSnackBar({ message, show: true, type: "error" });
  }

  return Promise.reject(error);
};
