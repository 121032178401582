import Service from "@/services/empresa.service"
import { Pagamento, PagePagamentoInput } from '@/models/pagamento'
import { PageEmpresaInput } from '@/models/empresa'


export const empresa = {
  namespaced: true,
  state: {
    table:  {
      header: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id"
        },
        {
          text: "Empresa",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "CNPJ",
          align: "start",
          sortable: true,
          value: "cnpj"
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email"
        },
        {
          text: "Token",
          align: "start",
          sortable: true,
          value: "token"
        },
      ],
      content: [] as Array<Pagamento>,
      paginate: {
        input: {
          name: "",
          cnpj: ""
        },
        inputPage: {
          page: 1,
          size: 10,
          order: 'id',
          descingOrder: false,
        },
      },
      isLoading: false,
      total: 0
    },
  },
  mutations: {
    setTableIsLoading(state: any, value: boolean): void {
      state.table.isLoading = value
    },
    setTableContent(state: any, value: Pagamento[]): void {
      state.table.content = value
    },
    setTableTotal(state: any, value: number): void {
      state.table.total = value
    },
    setTablePaginate(state: any, value: PageEmpresaInput): void {
      state.table.paginate = value
    },
  },
  actions: {
    SEARCH({ commit, dispatch, state }: any): void {
      commit('setTableIsLoading', true)
      Service.page(state.table.paginate).subscribe(({items, totalItems})=>{
        commit('setTableContent', items)
        commit('setTableTotal', totalItems)
        commit('setTableIsLoading', false)
      }, (error)=>{
        commit('setTableIsLoading', false)
      })
    },
    CHANGE_PAGE({ commit, dispatch, state }: any, value: PagePagamentoInput): void {
      commit('setTablePaginate', value)
    },
    CLEAR({ commit }: any): void {
      commit('setTableContent', [])
      commit('setTableTotal', 0)
      commit('setTableIsLoading', false)
      commit('setTablePaginate', {
        input: {
          name: "",
          cnpj: ""
        },
        inputPage: {
          page: 1,
          size: 10,
          order: 'id',
          descingOrder: false,
        },
      })
    },
  },
}
