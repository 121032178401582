import PerfisService from "@/services/perfis.service"
import { Perfil, PagePerfilInput } from '@/models/perfil'
import { router } from '@/router'


export const perfil = {
  namespaced: true,
  state: {
    table:  {
      header: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id"
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Key",
          align: "start",
          sortable: true,
          value: "key"
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: "actions",
          width: "100px"
        }
      ],
      content: [] as Array<Perfil>,
      paginate: {
        input: {
          name: "",
          key: "",
        },
        inputPage: {
          page: 1,
          size: 10,
          order: 'id',
          descendingOrder: false,
        },
      },
      isLoading: false,
      total: 0
    },
    detail: {
      data: {
        name: "",
        key: "",
        profilesFunctionalities: [] as Array<any>
      },
      isLoading: false
    }
  },
  mutations: {
    setTableIsLoading(state: any, value: boolean): void {
      state.table.isLoading = value
    },
    setTableContent(state: any, value: Perfil[]): void {
      state.table.content = value
    },
    setTableTotal(state: any, value: number): void {
      state.table.total = value
    },
    setTablePaginate(state: any, value: PagePerfilInput): void {
      state.table.paginate = value
    },
    setDetail(state: any, value: Perfil): void {
      state.detail.data = value
    },
    setDetailIsLoading(state: any, value: boolean): void {
      state.detail.isLoading = value
    }

  },
  actions: {
    SEARCH({ commit, dispatch, state }: any): void {
      commit('setTableIsLoading', true)
      PerfisService.page(state.table.paginate).subscribe(({items, totalItems})=>{
        commit('setTableContent', items)
        commit('setTableTotal', totalItems)
        commit('setTableIsLoading', false)
      }, (error)=>{
        commit('setTableIsLoading', false)
      })
    },
    CHANGE_PAGE({ commit, dispatch, state }: any, value: PagePerfilInput): void {
      commit('setTablePaginate', value)
    },
    CLEAR({ commit }: any): void {
      commit('setTableContent', [])
      commit('setTableTotal', 0)
      commit('setTableIsLoading', false)
      commit('setTablePaginate', {
        input: {
          name: "",
          key: "",
        },
        inputPage: {
          page: 1,
          size: 10,
          order: 'id',
          descendingOrder: false,
        },
      })
    },
    SHOW({ commit, dispatch, state }: any, value: number): void {
      commit('setDetailIsLoading', true)
      PerfisService.show(value).subscribe((res)=>{
        commit('setDetail', res)
        commit('setDetailIsLoading', false)
      }, (error)=>{
        commit('setDetailIsLoading', false)
      })
    },
    CLEAR_DETAIL({ commit, dispatch, state }: any): void {
      commit('setDetail', {name: "",key: "", profilesFunctionalities: []})
      commit('setDetailIsLoading', false)
    },
    CREATE({ commit, dispatch, state }: any, value: Perfil) {
      commit('setDetailIsLoading', true)
      PerfisService.create(value).subscribe((res)=>{
        commit('setDetail', res)
        commit('setDetailIsLoading', false)
        commit("global/setSnackBar", {show: true, message: "Recurso criado com sucesso", type: "success"}, {root: true});
        router.push({name: 'dashboard-perfis-list'})
      }, (error)=>{
        commit('setDetailIsLoading', false)
      })
    },
    UPDATE({ commit, dispatch, state }: any, value: Perfil) {
      commit('setDetailIsLoading', true)
      PerfisService.update(value).subscribe((res)=>{
        commit('setDetail', res)
        commit('setDetailIsLoading', false)
        commit("global/setSnackBar", {show: true, message: "Recurso atualizado com sucesso", type: "success"}, {root: true});
        router.push({name: 'dashboard-perfis-list'})
      }, (error)=>{
        commit('setDetailIsLoading', false)
      })
    },
    async LIST({ commit, dispatch, state }: any): Promise<Perfil[]> {
      try {
        return PerfisService.list().toPromise()
      } catch (error) {
        // commit('setDetailIsLoading', false)
      }
    }
  },
}
