<template>
  <v-app class="app">
    <template v-if="!publicPage">
      <v-layout>
        <v-main>
          <app-toolbar />
          <app-drawer />
          <app-banner />
          <app-page-header />
          <router-view />
        </v-main>

        <app-fab />
        <app-snack-bar />
        <app-snack-bar-content />
      </v-layout>
    </template>

    <template v-else>
      <router-view :key="$route.fullPath" />
    </template>
  </v-app>
</template>

<script>
  import Vue from 'vue';
  import AppFab from '@/components/AppFab';
  import AppBanner from '@/components/AppBanner';
  import AppDrawer from '@/components/AppDrawer';
  import AppToolbar from '@/components/AppToolbar';
  import AppPageHeader from '@/components/AppPageHeader';
  import AppSnackBar from '@/components/AppSnackBar';
  import HasPrivilleges from '@/directives/privilleges';
  import AppSnackBarContent from '@/components/AppSnackBarContent';

  import Filters from '@/filters';

  import VueTheMask from 'vue-the-mask';
  Vue.use(VueTheMask);

  import money from 'v-money';
  Vue.use(money, { precision: 4 });

  export default Vue.extend({
    name: 'App',
    components: {
      AppFab,
      AppBanner,
      AppDrawer,
      AppPageHeader,
      AppToolbar,
      AppSnackBar,
      AppSnackBarContent
    },
    filters: {
      Filters
    },
    directives: {
      HasPrivilleges
    },
    computed: {
      publicPage() {
        const {
          fullPath,
          meta: { publicPage }
        } = this.$route;

        return fullPath === '/' ? true : publicPage;
      }
    }
  });
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
.app {
  background-color: #f1f1f1 !important;
  /* background: url("./assets/images/background.png") #f1f1f1 !important;
  background-repeat: no-repeat !important;
  background-position: right bottom !important;
  background-attachment: fixed !important;
  background-size: contain !important; */

  &-main {
    min-height: 590px;
  }
}

.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}
</style>
