import { User } from '@/models/user'
import { BaseAPI } from '@/api/base.api'
import { BACK_URL_SERVICE } from '@/commons/config'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'

export interface IResetPasswordInput {
  password: string;
  passwordConfirmation: string;
  token: string;
}

export class UserAPI extends BaseAPI<User> {
  public signin (usernameOrEmail: string, password: string): AxiosObservable<User> {
    return this.axios.post(`${BACK_URL_SERVICE}/auth/users/login`, { email: usernameOrEmail, password})
  }

  public forgotPassword (email: string): AxiosObservable<any> {
    return this.axios.post(`${BACK_URL_SERVICE}/auth/users/forgot-password`, { email })
  }

  public resetPassword (data: IResetPasswordInput): AxiosObservable<any> {
    return this.axios.post(`${BACK_URL_SERVICE}/auth/users/reset-password`, data)
  }
}
