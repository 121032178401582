import { print } from "graphql";
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagination } from './pagination';
import { PageUsuarioInput, Usuario } from '@/models/usuario';

const usuarioPage  = require('@/query/usuario/Page.graphql')
const usuarioShow  = require('@/query/usuario/Show.graphql')

export class UsuarioAPI extends BaseAPI<Usuario> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/users`;
  }


  public page(
    input: PageUsuarioInput
  ): AxiosObservable<Pagination<Usuario>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(usuarioPage),
        variables: { ...input }
      })
  }

  public show(id: number): AxiosObservable<Usuario> {
    return this.axios.post(`${this.graphqlUrl}`, {query: print(usuarioShow), variables: { id }})
  }

  public update (id: number, body: Usuario): AxiosObservable<Usuario> {
    return this.axios.patch(`${this.backUrl}/${id}`, body)
  }
}
