import { Pagination } from '@/api/pagination';
import {  Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmpresaAPI } from '@/api/empresa.api';
import { PageEmpresaInput, Empresa } from '@/models/empresa';
import { AxiosObservable } from 'axios-observable';

const api = new EmpresaAPI();

const page = (input: PageEmpresaInput): Observable<Pagination<Empresa>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageCompanies: { items, totalItems } } = data;
    return { items, totalItems }
  }));
}

const getCompany = (id:number):Observable<any> =>{
  return api.getCompany(id)
}

const updateCompany = (id:number,body:object): AxiosObservable<any> => {
  return api.updateCompany(id,body);
}

export default {
  page,
  getCompany,
  updateCompany
}
