


































import Vue from 'vue';
import GlobalStore from '@/store/modules/global';
import AuthenticationService from '@/services/authentication.service';

export default Vue.extend({
  data: () => ({}),
  computed: {
    show: {
      get: () => GlobalStore.banner.show,
      set: (show: boolean) => GlobalStore.setBanner({ show })
    },
    message: () => GlobalStore.banner.message,
    color: () => GlobalStore.banner.color
  },
  methods: {
    logout() {
      AuthenticationService.logout();
      GlobalStore.setBanner({ show: false });
      GlobalStore.setSnackBar({
        show: true,
        message: 'Saiu do sistema com sucesso.',
        type: 'success'
      });
    }
  }
});
