import { print } from "graphql";
import { BaseAPI } from "@/api/base.api";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { Pagination } from "./pagination";
import { Pagamento, PagePagamentoInput } from "@/models/pagamento";

const pagamentoPage = require("@/query/pagamento/Page.graphql");
const pagamentoShow = require("@/query/pagamento/Show.graphql");

export class PagamentoAPI extends BaseAPI<Pagamento> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/payments`;
    this.apiUrl = `${this.apiUrl}/api_dash/v1/payments`;
  }

  public page(
    input: PagePagamentoInput
  ): AxiosObservable<Pagination<Pagamento>> {
    return this.axios.post(`${this.graphqlUrl}`, {
      query: print(pagamentoPage),
      variables: { ...input },
    });
  }

  public show(id: number): AxiosObservable<Pagamento> {
    return this.axios.post(`${this.graphqlUrl}`, {
      query: print(pagamentoShow),
      variables: { id },
    });
  }

  public update_payment(
    id: number,
    body: Pagamento
  ): AxiosObservable<Pagamento> {
    return this.axios.put(`${this.apiUrl}/${id}`, body);
  }
  
}
