import { Pagination } from '@/api/pagination';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { TipoPedidoAPI } from '@/api/tipo-pedido.api';
import { PageTipoPedidoInput, TipoPedido } from '@/models/tipo-pedido';

const api = new TipoPedidoAPI();

const page = (input: PageTipoPedidoInput): Observable<Pagination<TipoPedido>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageTypeSolicitations: { items, totalItems } } = data;
    return { items, totalItems }
  }));
}

const show = (id: number): Observable<TipoPedido> => {
  return api.show(id).pipe(map((data: any) => {
    const { showTypeSolicitation } = data;
    return showTypeSolicitation
  }));
}

const create = (tipoPedido: TipoPedido): AxiosObservable<TipoPedido> => {
  return api.create(tipoPedido)
}

const update = (tipoPedido: TipoPedido): AxiosObservable<TipoPedido> => {
  return api.update(tipoPedido.id, tipoPedido)
}

const remove = (id: number): AxiosObservable<any> => {
  return api.delete(id)
}

export default {
  page,
  show,
  create,
  update,
  remove
}
