import { Pagination } from '@/api/pagination';
import { of, Observable, async } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { UsuarioAPI } from '@/api/usuario.api';
import { Usuario, PageUsuarioInput } from '@/models/usuario';

const api = new UsuarioAPI();

const page = (input: PageUsuarioInput): Observable<Pagination<Usuario>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageUsers: { items, totalItems } } = data;
    return { items, totalItems }
  }));
}

const show = (id: number): Observable<Usuario> => {
  return api.show(id).pipe(map((data: any) => {
    const { showUser } = data;
    return showUser
  }));
}

const create = (usuario: Usuario): AxiosObservable<Usuario> => {
  return api.create(usuario)
}

const update = (usuario: Usuario): AxiosObservable<Usuario> => {
  return api.update(usuario.id, usuario)
}

export default {
  page,
  show,
  create,
  update
}
