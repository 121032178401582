import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { DocumentoAPI } from '@/api/documento.api';
import { Documento, PayloadUpdateStatusDocumento, PayloadUpdateInfosDocumento, PageDocumentoInput } from '@/models/documento';
import { Observable } from 'rxjs';
import { Pagination } from '@/api/pagination';
import { map } from 'rxjs/operators';

const api = new DocumentoAPI();

const updateStatus = (id: number, body: PayloadUpdateStatusDocumento): AxiosObservable<Documento> => {
  return api.updateStatus(id, body)
}

const updateInfos = (id: number, body: PayloadUpdateInfosDocumento): AxiosObservable<Documento> => {
  return api.updateInfos(id, body)
}

const download = (body: Documento): AxiosObservable<any> =>
api.download(body);

const doDownloadFile = (data: any, file: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", file);
  document.body.appendChild(link);
  link.click();
};

const page = (input: PageDocumentoInput): Observable<Pagination<Documento>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageDocuments: { items, totalItems } } = data;
    return { items, totalItems }
  }));
}

export default {
  updateStatus,
  updateInfos,
  download,
  doDownloadFile,
  page
}
