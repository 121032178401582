import Service from "@/services/tipo-pedido.service"
import { router } from '@/router'
import { TipoPedido, PageTipoPedidoInput } from '@/models/tipo-pedido'


export const tipoPedido = {
  namespaced: true,
  state: {
    table:  {
      header: [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id"
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "value"
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: "actions",
          width: "100px"
        }
      ],
      content: [] as Array<TipoPedido>,
      paginate: {
        input: {
          name: "",
        },
        inputPage: {
          page: 1,
          size: 10,
          order: 'id',
          descingOrder: false,
        },
      },
      isLoading: false,
      total: 0
    },
    detail: {
      data: {
        name: "",
        value: "",
        key: ""
      },
      isLoading: false
    }
  },
  mutations: {
    setTableIsLoading(state: any, value: boolean): void {
      state.table.isLoading = value
    },
    setTableContent(state: any, value: TipoPedido[]): void {
      state.table.content = value
    },
    setTableTotal(state: any, value: number): void {
      state.table.total = value
    },
    setTablePaginate(state: any, value: PageTipoPedidoInput): void {
      state.table.paginate = value
    },
    setDetail(state: any, value: TipoPedido): void {
      state.detail.data = value
    },
    setDetailIsLoading(state: any, value: boolean): void {
      state.detail.isLoading = value
    }

  },
  actions: {
    SEARCH({ commit, dispatch, state }: any): void {
      commit('setTableIsLoading', true)
      Service.page(state.table.paginate).subscribe(({items, totalItems})=>{
        commit('setTableContent', items)
        commit('setTableTotal', totalItems)
        commit('setTableIsLoading', false)
      }, (error)=>{
        commit('setTableIsLoading', false)
      })
    },
    CHANGE_PAGE({ commit, dispatch, state }: any, value: PageTipoPedidoInput): void {
      commit('setTablePaginate', value)
    },
    CLEAR({ commit }: any): void {
      commit('setTableContent', [])
      commit('setTableTotal', 0)
      commit('setTableIsLoading', false)
      commit('setTablePaginate', {
        input: {
          name: "",
        },
        inputPage: {
          page: 1,
          size: 10,
          order: 'id',
          descingOrder: false,
        },
      })
    },
    async SHOW({ commit, dispatch, state }: any, value: number): Promise<any> {
      commit('setDetailIsLoading', true)
      try {
        const res = await Service.show(value).toPromise()
        commit('setDetail', res)
        commit('setDetailIsLoading', false)
      } catch (error) {
        commit('setDetailIsLoading', false)
      }
    },
    CLEAR_DETAIL({ commit, dispatch, state }: any): void {
      commit('setDetail', {name: "",value: ""})
      commit('setDetailIsLoading', false)
    },
    CREATE({ commit, dispatch, state }: any, value: TipoPedido) {
      commit('setDetailIsLoading', true)
      Service.create(value).subscribe((res)=>{
        commit('setDetail', res)
        commit('setDetailIsLoading', false)
        commit("global/setSnackBar", {show: true, message: "Criado com sucesso", type: "success"}, {root: true});
        router.push({name: 'dashboard-tipo-pedido-list'})
      }, (error)=>{
        commit('setDetailIsLoading', false)
      })
    },
    UPDATE({ commit, dispatch, state }: any, value: TipoPedido) {
      commit('setDetailIsLoading', true)
      Service.update(value).subscribe((res)=>{
        commit('setDetail', res)
        commit('setDetailIsLoading', false)
        commit("global/setSnackBar", {show: true, message: "Recurso atualizado com sucesso", type: "success"}, {root: true});
        router.push({name: 'dashboard-tipo-pedido-list'})
      }, (error)=>{
        commit('setDetailIsLoading', false)
      })
    },
    DELETE({ commit, dispatch, state }: any, id: number) {
      Service.remove(id).subscribe((res)=>{
        commit("global/setSnackBar", {show: true, message: "Recurso deletado com sucesso", type: "success"}, {root: true});
        dispatch('SEARCH')

      }, (error)=>{
        commit('setDetailIsLoading', false)
      })
    }
  },
}
