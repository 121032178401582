<template>
  <v-navigation-drawer
    id="appDrawer"
    v-model="drawer"
    :mini-variant.sync="mini"
    fixed
    :dark="$vuetify.dark"
    app
    width="230"
  >
    <v-toolbar elevation="0">
      <router-link
        to="/"
        title="SISTRANSCE - SISTEMA DE TRANSPORTE DO CEARÁ"
        class="app-logo"
      >
        <img
          src="../assets/images/logo.svg"
          height="auto"
          width="160"
          class="mat-toolbar__img"
          alt="Logo Sistransce"
        >
      </router-link>
    </v-toolbar>

    <app-side-bar />

    <template v-slot:append>
      <div class="pa-2 text-center app-version">
        <span class="body-2">&copy; {{ currentYear.getFullYear() }} {{ version }}</span>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import GlobalStore from '@/store/modules/global';
  import AppSideBar from '@/components/AppSideBar';
  import { VERSION_APP } from '@/commons/config';

  export default {
    components: {
      AppSideBar
    },
    data: () => ({
      mini: false,
      version: `v${VERSION_APP}`,
      currentYear: new Date()
    }),
    computed: {
      drawer: {
        get: () => {
          return GlobalStore.drawer;
        },
        set: value => {
          GlobalStore.setDrawer(value);
        }
      }
    },
    created() {
      const { name } = this.$vuetify.breakpoint;
      if (name === 'xs') {
        GlobalStore.setDrawer(false);
      }
    }
  };
</script>

<style lang="scss">
#appDrawer {
  overflow: hidden;

  .drawer-menu--scroll {
    height: calc(100vh - 48px);
    overflow: auto;
  }

  .mat-toolbar__img {
    margin: auto;
    display: table;
  }
}
</style>
