import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { PedidoAPI } from "@/api/pedido.api";
import {
  PagePedidoInput,
  Pedido,
  PayloadUpdateStatusPedido,
} from "@/models/pedido";
import { Pagination } from "@/api/pagination";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const pedidoAPI = new PedidoAPI();

const page = (input: PagePedidoInput): Observable<Pagination<Pedido>> => {
  return pedidoAPI.page(input).pipe(
    map((data: any) => {
      const {
        pageSolicitations: { items, totalItems },
      } = data;
      return { items, totalItems };
    })
  );
};

const show = (id: number): Observable<Pedido> => {
  return pedidoAPI.show(id).pipe(
    map((data: any) => {
      const { showSolicitation } = data;
      return showSolicitation;
    })
  );
};

const updateStatus = (
  id: number,
  body: PayloadUpdateStatusPedido
): AxiosObservable<Pedido> => {
  return pedidoAPI.updateStatus(id, body);
};
const showPaymentsGroup = (group: string): AxiosObservable<any> => {
  return pedidoAPI.showPaymentsGroup(group);
};
const createAutovistoria = (
  solicitaionId: string,
  body: object
): AxiosObservable<any> => {
  return pedidoAPI.createAutovistoria(solicitaionId, body);
};
const getAllAutovistoria = (solicitaionId: string): AxiosObservable<any> => {
  return pedidoAPI.getAllAutovistoria(solicitaionId);
};
const detailsAutovistoria = (
  solicitaionId: string,
  key: string
): AxiosObservable<any> => {
  return pedidoAPI.detailsAutovistoria(solicitaionId, key);
};
const updateApproveImages = (
  solicitaionId: string,
  inspectionKey: string,
  key: string,
  body: object
): AxiosObservable<any> => {
  return pedidoAPI.updateApproveImages(solicitaionId, inspectionKey, key, body);
};
const updateVehicleInformation = (
  solicitaionId: string,
  inspectionKey: string,
  key: string,
  body: object
): AxiosObservable<any> => {
  return pedidoAPI.updateVehicleInformation(
    solicitaionId,
    inspectionKey,
    key,
    body
  );
};
const deferAutovistoria = (
  solicitaionId: string,
  inspectionKey: string
): AxiosObservable<any> => {
  return pedidoAPI.deferAutovistoria(solicitaionId, inspectionKey);
};
const denyAutovistoria = (
  solicitaionId: string,
  inspectionKey: string,
  rejectReason:string
): AxiosObservable<any> => {
  return pedidoAPI.denyAutovistoria(solicitaionId, inspectionKey, rejectReason);
};
const updateItemChecklist = (
  solicitaionId: string,
  inspectionKey: string,
  key: string,
  body: object
): AxiosObservable<any> => {
  return pedidoAPI.updateItemChecklist(solicitaionId, inspectionKey, key, body);
};
const finishChecklist = (
  solicitaionId: string,
  inspectionKey: string,
  key: string
): AxiosObservable<any> => {
  return pedidoAPI.finishChecklist(solicitaionId, inspectionKey, key);
};
const resendAutovistoria = (
  solicitaionId: string,
  key: string
): AxiosObservable<any> => {
  return pedidoAPI.resendAutovistoria(solicitaionId, key);
};
const sendEvidenceRefusend = (
  solicitaionId: string,
  key: string
): AxiosObservable<any> => {
  return pedidoAPI.sendEvidenceRefusend(solicitaionId, key);
};

const updateAutovistoria = (
  solicitaionId: string,
  key: string,
  body: object
): AxiosObservable<any> => {
  return pedidoAPI.updateAutovistoria(solicitaionId, key, body);
};
const generateReport = (
  solicitaionId: string,
  key: string
): AxiosObservable<any> => {
  return pedidoAPI.generateReport(solicitaionId, key);
};
const uploadReport = (
  solicitaionId: string,
  key: string,
  content: File
): AxiosObservable<any> => {

  return pedidoAPI.uploadReport(solicitaionId, key, content);
};
export default {
  page,
  show,
  updateStatus,
  showPaymentsGroup,
  createAutovistoria,
  getAllAutovistoria,
  detailsAutovistoria,
  updateApproveImages,
  updateVehicleInformation,
  deferAutovistoria,
  denyAutovistoria,
  updateItemChecklist,
  resendAutovistoria,
  sendEvidenceRefusend,
  updateAutovistoria,
  finishChecklist,
  generateReport,
  uploadReport
};
