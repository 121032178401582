import { print } from "graphql";
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagination } from './pagination';
import { Funcionalidade, PageFuncionalidadeInput } from '@/models/funcionalidade';
import { Empresa, PageEmpresaInput } from '@/models/empresa';

const companiesPage  = require('@/query/empresa/Page.graphql')

export class EmpresaAPI extends BaseAPI<Empresa> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/companies`;
    this.apiUrl = `${this.apiUrl}/api_dash/v1/companies/`
  }


  public page(
    input: PageEmpresaInput
  ): AxiosObservable<Pagination<Empresa>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(companiesPage),
        variables: { ...input }
      })
  }

  public getCompany(id:number): AxiosObservable<Empresa>{
    return this.axios.get(`${this.apiUrl}${id}`)
  }

  public updateCompany(id:number,body:Object):AxiosObservable<any>{
    return this.axios.patch(`${this.apiUrl}${id}`,body)
  }
}
