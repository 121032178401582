import { Sector } from '@/models/sector'
import { BaseModel } from '@/models/base'

export class User extends BaseModel {
  constructor (
    public id: number,
    public name?: string,
    public username?: string,
    public email?: string,
    public sector?: Sector,
    public functionalities?: String[],
    public roles?: Array<string>,
    public crea?: number,
    public profile?: number
  ) { super(id) }
}
