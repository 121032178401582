export enum StatusPedido {
  // ABERTO = 'ABERTO',
  AGUARDANDO_PAGAMENTO = 'AGUARDANDO_PAGAMENTO',
  PAGAMENTO_PENDENTE = 'PAGAMENTO_PENDENTE',
  // PAGAMENTO_FINALIZADO = 'PAGAMENTO_FINALIZADO',
  AGUARDANDO_DOCUMENTACAO = 'AGUARDANDO_DOCUMENTACAO',
  DOCUMENTACAO_PENDENTE = 'DOCUMENTACAO_PENDENTE',
  EM_ANALISE = 'EM_ANALISE',
  DEFERIDO = 'DEFERIDO',
  INDEFERIDO = 'INDEFERIDO',
  ENVIADO_PARA_ARCE = 'ENVIADO_PARA_ARCE',
  CANCELADO_POR_FALTA_PAGAMENTO = 'CANCELADO_POR_FALTA_PAGAMENTO',
  AGUARDANDO_ANALISE = 'AGUARDANDO_ANALISE',
  AGUARDANDO_AUTOVISTORIA = 'AGUARDANDO_AUTOVISTORIA',
  AUTOVISTORIA_ANDAMENTO ='AUTOVISTORIA_ANDAMENTO',
  AUTOVISTORIA_INDEFERIDA = 'AUTOVISTORIA_INDEFERIDA',
  AUTOVISTORIA_DEFERIDA = 'AUTOVISTORIA_DEFERIDA'

}

// export enum StatusPedidoEnum {
//   // ABERTO = 'ABERTO',
//   AGUARDANDO_PAGAMENTO = 'Aguardando Pagamento',
//   PAGAMENTO_PENDENTE = 'Pagamento Pendente',
//   // PAGAMENTO_FINALIZADO = 'PAGAMENTO_FINALIZADO',
//   AGUARDANDO_DOCUMENTACAO = 'Aguardando Documentação',
//   DOCUMENTACAO_PENDENTE = 'Documentação Pendente',
//   EM_ANALISE = 'Em Análise',
//   DEFERIDO = 'Deferido',
//   INDEFERIDO = 'Indeferido',
//   ENVIADO_PARA_ARCE = 'Enviado para ARCE',
//   CANCELADO_POR_FALTA_PAGAMENTO = 'Cancelado por falta de pagamento',
//   AGUARDANDO_ANALISE = 'Aguardando Análise',
// }

export enum StatusPedidoEnum {
  'AGUARDANDO_PAGAMENTO' = 'Aguardando Pagamento',
  'PAGAMENTO_PENDENTE' = 'Pagamento Pendente',
  'AGUARDANDO_ANALISE' = 'Aguardando Análise',
  'AGUARDANDO_DOCUMENTACAO' = 'Aguardando Documentação',
  'DOCUMENTACAO_PENDENTE' = 'Documentação Pendente',
  'EM_ANALISE' = 'Em Análise',
  'DEFERIDO' = 'Deferido',
  'INDEFERIDO' = 'Indeferido',
  'ENVIADO_PARA_ARCE' = 'Enviado para ARCE',
  'DEFERIDO_PELA_ARCE' = 'Deferido pela ARCE',
  'INDEFERIDO_PELA_ARCE' = 'Indeferido pela ARCE',
  'DOCUMENTACAO_PENDENTE_PELA_ARCE' = 'Documentação Pendente pela ARCE',
  'CANCELADO_POR_FALTA_PAGAMENTO' = 'Cancelador por falta de pagamento',
  'CANCELADO' = 'Cancelado',
  'AGUARDANDO_ANALISE_ARCE' = 'Aguardando Análise ARCE',
  'AGUARDANDO_DOCUMENTACAO_ARCE' = 'Aguardando Documentação ARCE',
  'DOCUMENTACAO_PENDENTE_ARCE' = 'Documentação Pendente ARCE',
  'APTO_PARA_EMITIR_CERTIDAO_PODER_CONCEDENTE_ARCE' = 'Apto para emitir certidão de porder concedente ARCE',
  'EM_ANALISE_ARCE' = 'Em Análise ARCE',
  'CANCELADO_ARCE' = 'Cancelado ARCE',
  'EMITIDO_CI_ARCE' = 'Emitido CI ARCE',
  'NAO_ACEITO_PELA_ARCE' = 'Não aceito pela ARCE',
  'AGUARDANDO_AUTOVISTORIA' = 'Aguardando Autovistoria',
  'AUTOVISTORIA_ANDAMENTO' ='Autovistoria em andamento',
  'AUTOVISTORIA_INDEFERIDA' = 'Autovistoria indeferida',
  'AUTOVISTORIA_DEFERIDA' = 'Autovistoria deferida'
}
