import { print } from "graphql";
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagination } from './pagination';
import { TipoPedido, PageTipoPedidoInput } from '@/models/tipo-pedido';

const tipoPedidoPage  = require('@/query/tipo-pedido/Page.graphql')
const tipoPedidoShow  = require('@/query/tipo-pedido/Show.graphql')

export class TipoPedidoAPI extends BaseAPI<TipoPedido> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/type-solicitations`;
  }


  public page(
    input: PageTipoPedidoInput
  ): AxiosObservable<Pagination<TipoPedido>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(tipoPedidoPage),
        variables: { ...input }
      })
  }

  public show(id: number): AxiosObservable<TipoPedido> {
    return this.axios.post(`${this.graphqlUrl}`, {query: print(tipoPedidoShow), variables: { id }})
  }

  public update (id: number, body: TipoPedido): AxiosObservable<TipoPedido> {
    return this.axios.patch(`${this.backUrl}/${id}`, body)
  }
}
