import { Pagination } from "@/api/pagination";
import { of, Observable, async } from "rxjs";
import { map } from "rxjs/operators";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { PagamentoAPI } from "@/api/pagamento.api";
import { PagePagamentoInput, Pagamento } from "@/models/pagamento";

const api = new PagamentoAPI();

const page = (input: PagePagamentoInput): Observable<Pagination<Pagamento>> => {
  return api.page(input).pipe(
    map((data: any) => {
      const {
        pagePayments: { items, totalItems },
      } = data;
      return { items, totalItems };
    })
  );
};

const show = (id: number): Observable<Pagamento> => {
  return api.show(id).pipe(
    map((data: any) => {
      const { showPayment } = data;
      return showPayment;
    })
  );
};

const create = (pagamento: Pagamento): AxiosObservable<Pagamento> => {
  return api.create(pagamento);
};

const update = (
  id: number,
  pagagamento: Pagamento
): AxiosObservable<Pagamento> => {
  return api.update(id, pagagamento);
};

const update_payment = (
  id: number,
  pagagamento: Pagamento
): AxiosObservable<Pagamento> => {
  return api.update_payment(id, pagagamento);
};
export default {
  page,
  show,
  create,
  update,
  update_payment,
};
