import { Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators'
import AuthenticationService from '@/services/authentication.service'
import { User } from '@/models/user'
import store from '@/store'

export interface SnackbarModel {
  show: Boolean,
  timeout?: number,
  message?: string,
  type?: string
}

export interface BannerModel {
  show: Boolean,
  message?: string,
  color?: string
}

@Module({
  dynamic: true,
  namespaced: true,
  name: 'global',
  store
})
class GlobalModule extends VuexModule {
  drawer: Boolean = true;
  user: User = AuthenticationService.currentUserValue;
  token: string = AuthenticationService.token;
  snackbar: SnackbarModel = { show: false, timeout: 6000, message: '', type: '' };
  snackbarUpdate: SnackbarModel = { show: false, timeout: -1, message: '', type: '' };
  banner: BannerModel = { show: false, message: '', color: '' };

  get functionalities (): String[] {
    const { functionalities = [] } = this.user || {}
    return functionalities
  }

  @Mutation
  toggleDrawer (): void {
    this.drawer = !this.drawer
  }

  @Mutation
  setDrawer (drawer: Boolean): void {
    this.drawer = drawer
  }

  @Mutation
  setUser (user: User): void {
    this.user = user
  }

  @Mutation
  setSnackBar (snackbar: SnackbarModel) {
    this.snackbar.show = snackbar.show
    this.snackbar.type = snackbar.type || this.snackbar.type
    this.snackbar.timeout = snackbar.timeout || this.snackbar.timeout
    this.snackbar.message = snackbar.message || this.snackbar.message
  }

  @Mutation
  setSnackBarUpdate (snackbar: SnackbarModel) {
    this.snackbarUpdate.show = snackbar.show
    this.snackbarUpdate.type = snackbar.type || this.snackbarUpdate.type
    this.snackbarUpdate.timeout = snackbar.timeout || this.snackbarUpdate.timeout
    this.snackbarUpdate.message = snackbar.message || this.snackbarUpdate.message
  }

  @Mutation
  setBanner (banner: BannerModel) {
    this.banner.show = banner.show
    this.banner.color = banner.color || this.banner.color
    this.banner.message = banner.message || this.banner.message
  }
}

export default getModule(GlobalModule)
