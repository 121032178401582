import { print } from "graphql";
import {
  Pedido,
  PagePedidoInput,
  PayloadUpdateStatusPedido,
} from "@/models/pedido";
import { BaseAPI } from "@/api/base.api";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";
import { Pagination } from "./pagination";

const pedidoPage = require("@/query/pedido/Page.graphql");
const pedidoShow = require("@/query/pedido/Show.graphql");

export class PedidoAPI extends BaseAPI<Pedido> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/solicitations`;
    this.apiUrl = `${this.apiUrl}/api_dash/v1`;
  }

  public page(input: PagePedidoInput): AxiosObservable<Pagination<Pedido>> {
    return this.axios.post(`${this.graphqlUrl}`, {
      query: print(pedidoPage),
      variables: { ...input },
    });
  }

  public show(id: number): AxiosObservable<Pedido> {
    return this.axios.post(`${this.graphqlUrl}`, {
      query: print(pedidoShow),
      variables: { id },
    });
  }

  public updateStatus(
    id: number,
    body: PayloadUpdateStatusPedido
  ): AxiosObservable<Pedido> {
    return this.axios.patch(`${this.backUrl}/${id}/updateStatus`, { ...body });
  }

  public showPaymentsGroup(group: string): AxiosObservable<any> {
    return this.axios.get(
      `${this.apiUrl}/payments/?solicitation_group=${group}`
    );
  }

  public createAutovistoria(
    solicitaionId: string,
    body: object
  ): AxiosObservable<any> {
    return this.axios.post(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections`,
      body
    );
  }
  public getAllAutovistoria(solicitaionId: string): AxiosObservable<any> {
    return this.axios.get(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections`
    );
  }
  public detailsAutovistoria(
    solicitaionId: string,
    key: string
  ): AxiosObservable<any> {
    return this.axios.get(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${key}`
    );
  }
  public updateApproveImages(
    solicitaionId: string,
    inspectionKey: string,
    key: string,
    body: object
  ): AxiosObservable<any> {
    return this.axios.patch(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${inspectionKey}/requirements/${key}`,
      body
    );
  }
  public updateVehicleInformation(
    solicitaionId: string,
    inspectionKey: string,
    key: string,
    body: object
  ): AxiosObservable<any> {
    return this.axios.patch(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${inspectionKey}/vehicles/${key}`,
      body
    );
  }
  public deferAutovistoria(
    solicitaionId: string,
    key: string
  ): AxiosObservable<any> {
    return this.axios.post(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${key}/defer`
    );
  }
  public denyAutovistoria(
    solicitaionId: string,
    key: string,
    reject_reason: string
  ): AxiosObservable<any> {
    return this.axios.post(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${key}/deny`,{reject_reason}
    );
  }
  public updateItemChecklist(
    solicitaionId: string,
    inspectionKey: string,
    key: string,
    body: object
  ): AxiosObservable<any> {
    return this.axios.patch(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${inspectionKey}/checklist_items/${key}`,
      body
    );
  }

  public finishChecklist(
    solicitaionId: string,
    inspectionKey: string,
    key: string
  ): AxiosObservable<any> {
    return this.axios.post(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${inspectionKey}/checklist/${key}/finish`
    );
  }

  public resendAutovistoria(
    solicitaionId: string,
    key: string
  ): AxiosObservable<any> {
    return this.axios.post(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${key}/resend`
    );
  }

  public sendEvidenceRefusend(
    solicitaionId: string,
    key: string
  ): AxiosObservable<any> {
    return this.axios.post(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${key}/send_refuseds`
    );
  }

  public updateAutovistoria(
    solicitaionId: string,
    key: string,
    body: object
  ): AxiosObservable<any> {
    return this.axios.patch(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${key}`,
      body
    );
  }

  public generateReport(
    solicitaionId: string,
    key: string
  ): AxiosObservable<any> {
    return this.axios.post(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${key}/reports/generate_original`
    );
  }

  public uploadReport(
    solicitaionId: string,
    key: string,
    content: File
  ): AxiosObservable<any> {

    let formData = new FormData();
    formData.append("content", content);

    return this.axios.post(
      `${this.apiUrl}/solicitations/${solicitaionId}/inspections/${key}/reports/upload_signed`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }
}
