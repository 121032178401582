import { print } from "graphql";
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagination } from './pagination';
import { Perfil, PagePerfilInput } from '@/models/perfil';

const perfilPage  = require('@/query/perfil/Page.graphql')
const perfilList  = require('@/query/perfil/List.graphql')
const perfilShow  = require('@/query/perfil/Show.graphql')

export class PerfilAPI extends BaseAPI<Perfil> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/profiles`;
  }


  public page(
    input: PagePerfilInput
  ): AxiosObservable<Pagination<Perfil>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(perfilPage),
        variables: { ...input }
      })
  }

  public list(): AxiosObservable<Perfil[]> {
    return this.axios.post(`${this.graphqlUrl}`, {query: print(perfilList)})
  }

  public show(id: number): AxiosObservable<Perfil> {
    return this.axios.post(`${this.graphqlUrl}`, {query: print(perfilShow), variables: { id }})
  }

  public update (id: number, body: Perfil): AxiosObservable<Perfil> {
    return this.axios.patch(`${this.backUrl}/${id}`, body)
  }
}
