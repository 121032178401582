import { Pagination } from '@/api/pagination';
import { of, Observable, async } from 'rxjs';
import { map } from 'rxjs/operators';
import { PerfilAPI } from '@/api/perfil.api';
import { PagePerfilInput, Perfil } from '@/models/perfil';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';

const perfilAPI = new PerfilAPI();

const page = (input: PagePerfilInput): Observable<Pagination<Perfil>> => {
  return perfilAPI.page(input).pipe(map((data: any) => {
    const { pageProfiles: { items, totalItems } } = data;
    return { items, ...totalItems }
  }));
}

const list = (): Observable<Perfil[]> => {
  return perfilAPI.list().pipe(map((data: any) => {
    const { listProfiles } = data;
    return listProfiles
  }));
}

const show = (id: number): Observable<Perfil> => {
  return perfilAPI.show(id).pipe(map((data: any) => {
    const { showProfile } = data;
    return showProfile
  }));
}

const create = (perfil: Perfil): AxiosObservable<Perfil> => {
  return perfilAPI.create(perfil)
}

const update = (perfil: Perfil): AxiosObservable<Perfil> => {
  return perfilAPI.update(perfil.id, perfil)
}

export default {
  page,
  show,
  create,
  update,
  list
}
