import { BaseModel } from "@/models/base";
import { API_URL_SERVICE, BACK_URL_SERVICE } from "@/commons/config";
import { handleRequest, handleErrorRequest } from "@/helpers/handle-request";
import { handleErrorResponse, handleResponse } from "@/helpers/handle-response";

import Axios from "axios-observable";
import { AxiosObservable } from "axios-observable/dist/axios-observable.interface";

export interface APIModel<T extends BaseModel> {
  body?: T;
  pageIndex?: number;
  pageSize?: number;
}

export class BaseAPI<T extends BaseModel> {
  protected axios: Axios;
  protected backUrl: string;
  protected apiUrl: string;
  protected graphqlUrl: string;

  constructor() {
    this.axios = this.buildAxiosInstance();
    this.backUrl = BACK_URL_SERVICE;
    this.apiUrl = API_URL_SERVICE;
    this.graphqlUrl = `${BACK_URL_SERVICE}/graphql`;
  }

  public getAll(): AxiosObservable<T[]> {
    return this.axios.get(`${this.backUrl}`);
  }

  public paginator(model: APIModel<T>): AxiosObservable<any> {
    return this.axios.post(
      `${this.backUrl}/find?` +
        `${this.getNumberPage(model.pageIndex)}&` +
        `${this.getNumberRegistries(model.pageSize)}`,
      model.body
    );
  }

  public show(id: number): AxiosObservable<T> {
    return this.axios.get(`${this.backUrl}/${id}`);
  }

  public create(body: T): AxiosObservable<T> {
    return this.axios.post(`${this.backUrl}/`, body);
  }

  public update(id: number, body: T): AxiosObservable<T> {
    return this.axios.put(`${this.backUrl}/${id}`, body);
  }

  public delete(id: number): AxiosObservable<any> {
    return this.axios.delete(`${this.backUrl}/${id}`);
  }

  protected getNumberPage(pageIndex = 0): string {
    return `page=${pageIndex}`;
  }

  protected getNumberRegistries(pageSize = 15): string {
    return `size=${pageSize}`;
  }

  private buildAxiosInstance(): Axios {
    const axiosInstance = Axios.create({});
    this.requestInterceptor(axiosInstance);
    this.responseInterceptor(axiosInstance);

    return axiosInstance;
  }

  private requestInterceptor(axios: Axios): void {
    axios.interceptors.request.use(
      config => handleRequest(config),
      (error: any) => handleErrorRequest(error)
    );
  }

  private responseInterceptor(axios: Axios): void {
    axios.interceptors.response.use(
      response => handleResponse(response),
      (error: any) => handleErrorResponse(error)
    );
  }
}
