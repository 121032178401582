import PedidoService from "@/services/pedidos.service";

export interface InputFindAutovistoria {
	solicitationId: string,
	inspectionKey: string

}
export const autovistoria = {
	namespaced: true,
	state: {
		detail: {
			data: null as Object,
			isLoading: false,
		}
	},
	mutations: {
		setDetail(state: any, value: any): void {
			state.detail.data = value;
		},
		setDetailIsLoading(state: any, value: boolean): void {
			state.detail.isLoading = value;
		}
	},
	actions: {
		SET_AUTOVISTORIA({ commit }: any, data: any):void {
			commit("setDetail", data);
		},
		FIND_AUTOVISTORIA({ commit }: any, { solicitationId, inspectionKey }: InputFindAutovistoria): void {
			commit("setDetailIsLoading", true);
			PedidoService.detailsAutovistoria(solicitationId, inspectionKey).subscribe((response) => {
					commit("setDetail", response);
					commit("setDetailIsLoading", false);
				},
				(error) => {
					commit("setDetailIsLoading", false);
				}
			);
		},
		CLEAR_DETAIL({ commit }: any): void {
			commit("setDetail", null);
		}
	}
};
