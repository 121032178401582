import { Pagination } from '@/api/pagination';
import { of, Observable, async } from 'rxjs';
import { map } from 'rxjs/operators';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { FuncionalidadeAPI } from '@/api/funcionalidades.api';
import { PageFuncionalidadeInput, Funcionalidade } from '@/models/funcionalidade';

const api = new FuncionalidadeAPI();

const page = (input: PageFuncionalidadeInput): Observable<Pagination<Funcionalidade>> => {
  return api.page(input).pipe(map((data: any) => {
    const { pageFunctionalities: { items, totalItems } } = data;
    return { items, totalItems }
  }));
}

const show = (id: number): Observable<Funcionalidade> => {
  return api.show(id).pipe(map((data: any) => {
    const { showFunctionality } = data;
    return showFunctionality
  }));
}

const create = (funcionalidade: Funcionalidade): AxiosObservable<Funcionalidade> => {
  return api.create(funcionalidade)
}

const update = (funcionalidade: Funcionalidade): AxiosObservable<Funcionalidade> => {
  return api.update(funcionalidade.id, funcionalidade)
}

export default {
  page,
  show,
  create,
  update
}
