import { BaseAPI } from './base.api';
import { Acompanhamento, PageAcompanhamentoInput } from '@/models/acompanhamento';
import { Pagination } from './pagination';
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { print } from "graphql";

const acompanhamentoPage = require('@/query/acompanhamento/Page.graphql')

export class AcompanhamentoAPI extends BaseAPI<Acompanhamento> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/accompaniments`;
  }


  public page(
    input: PageAcompanhamentoInput
  ): AxiosObservable<Pagination<Acompanhamento>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(acompanhamentoPage),
        variables: { ...input }
      })
  }

  public sendToARCE(
    pedidoId: number
  ): AxiosObservable<Acompanhamento> {
    return this.axios
      .post(`${this.backUrl}/criar-acompanhamento-enviado-arce/${pedidoId}`)
  }
}
