






















































  import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'app-side-bar' })
  export default class AppSideBar extends Vue {
  itemsMenu: object[] = [
    {
      title: 'Início',
      icon: 'home',
      to: '/inicio',
      privilege: 'access_dashboard'
    },
    {
      title: 'Pedidos',
      icon: 'assignment_return',
      to: '/pedidos',
      privilege: 'list_solicitation'
    },
    {
      title: 'Pagamentos',
      icon: 'paid',
      to: '/pagamentos',
      privilege: 'list_payment'
    },
    {
      title: 'Empresas',
      icon: 'business',
      to: '/empresas',
      privilege: 'list_companies'
    }
  ];
  itemsSettings: object[] = [
    {
      title: 'Tipos de Pedido',
      icon: 'view_list',
      to: '/tipos-pedidos',
      privilege: 'list_type_solicitation'
    },
    {
      title: 'Perfis',
      icon: 'assignment_ind',
      to: '/perfis',
      privilege: 'list_profile'
    },
    {
      title: 'Funcionalidades',
      icon: 'admin_panel_settings',
      to: '/funcionalidades',
      privilege: 'list_functionality'
    },
    {
      title: 'Usuários',
      icon: 'group',
      to: '/usuarios',
      privilege: 'list_user'
    }
  ];
  }
