import Vue from "vue";
import Router, { Route } from "vue-router";
import GlobalStore from "@/store/modules/global";
import { BASE_PATH_APP } from "@/commons/config";

Vue.use(Router);

export const router = new Router({
  base: BASE_PATH_APP,
  routes: [
    {
      path: "/",
      redirect: {
        path: "/inicio"
      }
    },
    {
      path: "/inicio",
      name: "dashboard-home",
      meta: {
        publicPage: false,
        authorize: ["access_dashboard"],
        breadcrumb: "Inicio"
      },
      component: () =>
        import(/* webpackChunkName: "dashboard" */ "./views/dashboard/Home.vue")
    },

    {
      path: "/login",
      name: "login",
      meta: { publicPage: true },
      beforeEnter: (to: Route, from: Route, next: Function): void => {
        const currentUser = GlobalStore.user;
        if (to.name === "login" && currentUser) {
          return next({ name: "dashboard-home" });
        }

        return next();
      },
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/dashboard/Login.vue")
    },

    {
      path: "/forgot-password",
      name: "forgot-password",
      meta: { publicPage: true },
      beforeEnter: (to: Route, from: Route, next: Function): void => {
        const currentUser = GlobalStore.user;
        if (to.name === "forgot-password" && currentUser) {
          return next({ name: "dashboard-home" });
        }

        return next();
      },
      component: () =>
        import(
          /* webpackChunkName: "forgot-password" */ "./views/dashboard/ForgotPassword.vue"
        )
    },

    {
      path: "/reset-password",
      name: "reset-password",
      meta: { publicPage: true },
      beforeEnter: (to: Route, from: Route, next: Function): void => {
        const currentUser = GlobalStore.user;
        if (to.name === "reset-password" && currentUser) {
          return next({ name: "dashboard-home" });
        }

        return next();
      },
      component: () =>
        import(
          /* webpackChunkName: "reset-password" */ "./views/dashboard/ResetPassword.vue"
        )
    },

    {
      path: "/profile",
      name: "dashboard-profile",
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Perfil"
      },
      component: () =>
        import(
          /* webpackChunkName: "profile" */ "./views/dashboard/Profile.vue"
        )
    },

    {
      path: "/pedidos",
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Pedidos"
      },
      component: { render: h => h("router-view") },
      children: [
        {
          path: "",
          name: "dashboard-pedidos-list",
          meta: {
            publicPage: false,
            authorize: ["list_solicitation"]
          },
          component: () =>
            import(
              /* webpackChunkName: "pedidos-list" */ "./views/dashboard/pedidos/List.vue"
            )
        },
        {
          path: ":id",
          name: "dashboard-pedidos-detail",
          meta: {
            publicPage: false,
            authorize: ["list_solicitation"],
            breadcrumb: "Detalhe"
          },
          component: () =>
            import(
              /* webpackChunkName: "pedidos-detail" */ "./views/dashboard/pedidos/Detail.vue"
            )
        }
      ]
    },
    {
      path: "/pagamentos",
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Pagamentos"
      },
      component: { render: h => h("router-view") },
      children: [
        {
          path: "",
          name: "dashboard-pagamentos-list",
          meta: {
            publicPage: false,
            authorize: ["list_payment"]
          },
          component: () =>
            import(
              /* webpackChunkName: "pagamentos-list" */ "./views/dashboard/pagamentos/List.vue"
            )
        },
        {
          path: ":id",
          name: "dashboard-pagamentos-detail",
          meta: {
            publicPage: false,
            authorize: ["list_payment"],
            breadcrumb: "Detalhe"
          },
          component: () =>
            import(
              /* webpackChunkName: "pagamentos-detail" */ "./views/dashboard/pagamentos/Detail.vue"
            )
        }
      ]
    },
    {
      path: "/empresas",
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Empresas"
      },
      component: { render: h => h("router-view") },
      children: [
        {
          path: "",
          name: "dashboard-empresas-list",
          meta: {
            publicPage: false,
            authorize: ["list_companies"]
          },
          component: () =>
            import(
              /* webpackChunkName: "empresas-list" */ "./views/dashboard/empresas/List.vue"
            )
        }
      ]
    },
    {
      path: "/funcionalidades",
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Funcionalidades"
      },
      component: { render: h => h("router-view") },
      children: [
        {
          path: "",
          name: "dashboard-funcionalidades-list",
          meta: {
            publicPage: false,
            authorize: ["list_functionality"]
          },
          component: () =>
            import(
              /* webpackChunkName: "funcionalidades-list" */ "./views/dashboard/funcionalidades/List.vue"
            )
        },
        {
          path: "novo",
          name: "dashboard-funcionalidades-novo",
          meta: {
            publicPage: false,
            authorize: ["create_functionality"],
            breadcrumb: "Novo"
          },
          component: () =>
            import(
              /* webpackChunkName: "funcionalidades-novo" */ "./views/dashboard/funcionalidades/Form.vue"
            )
        },
        {
          path: ":id/editar",
          name: "dashboard-funcionalidades-editar",
          meta: {
            publicPage: false,
            authorize: ["update_functionality"],
            breadcrumb: "Editar"
          },
          component: () =>
            import(
              /* webpackChunkName: "funcionalidades-editar" */ "./views/dashboard/funcionalidades/Form.vue"
            )
        }
      ]
    },
    {
      path: "/usuarios",
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Usuários"
      },
      component: { render: h => h("router-view") },
      children: [
        {
          path: "",
          name: "dashboard-usuarios-list",
          meta: {
            publicPage: false,
            authorize: ["list_user"]
          },
          component: () =>
            import(
              /* webpackChunkName: "usuarios-list" */ "./views/dashboard/usuarios/List.vue"
            )
        },
        {
          path: "novo",
          name: "dashboard-usuarios-novo",
          meta: {
            publicPage: false,
            authorize: ["create_user"],
            breadcrumb: "Novo"
          },
          component: () =>
            import(
              /* webpackChunkName: "usuarios-novo" */ "./views/dashboard/usuarios/Form.vue"
            )
        },
        {
          path: ":id/editar",
          name: "dashboard-usuarios-editar",
          meta: {
            publicPage: false,
            authorize: ["update_user"],
            breadcrumb: "Detalhe"
          },
          component: () =>
            import(
              /* webpackChunkName: "usuarios-editar" */ "./views/dashboard/usuarios/Form.vue"
            )
        }
      ]
    },
    {
      path: "/perfis",
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Perfis"
      },
      component: { render: h => h("router-view") },
      children: [
        {
          path: "",
          name: "dashboard-perfis-list",
          meta: {
            publicPage: false,
            authorize: ["list_profile"]
          },
          component: () =>
            import(
              /* webpackChunkName: "perfis-list" */ "./views/dashboard/perfis/List.vue"
            )
        },
        {
          path: "novo",
          name: "dashboard-perfis-novo",
          meta: {
            publicPage: false,
            authorize: ["create_profile"],
            breadcrumb: "Novo"
          },
          component: () =>
            import(
              /* webpackChunkName: "perfis-novo" */ "./views/dashboard/perfis/Form.vue"
            )
        },
        {
          path: ":id/editar",
          name: "dashboard-perfis-editar",
          meta: {
            publicPage: false,
            authorize: ["update_profile"],
            breadcrumb: "Detalhe"
          },
          component: () =>
            import(
              /* webpackChunkName: "perfis-editar" */ "./views/dashboard/perfis/Form.vue"
            )
        }
      ]
    },
    {
      path: "/tipos-pedidos",
      meta: {
        publicPage: false,
        authorize: [],
        breadcrumb: "Tipos Pedidos"
      },
      component: { render: h => h("router-view") },
      children: [
        {
          path: "",
          name: "dashboard-tipo-pedido-list",
          meta: {
            publicPage: false,
            authorize: ["list_type_solicitation"]
          },
          component: () =>
            import(
              /* webpackChunkName: "tipo-pedido-list" */ "./views/dashboard/tipos-pedido/List.vue"
            )
        },
        {
          path: "novo",
          name: "dashboard-tipo-pedido-novo",
          meta: {
            publicPage: false,
            authorize: ["create_type_solicitation"],
            breadcrumb: "Novo"
          },
          component: () =>
            import(
              /* webpackChunkName: "tipo-pedido-novo" */ "./views/dashboard/tipos-pedido/Form.vue"
            )
        },
        {
          path: ":id/editar",
          name: "dashboard-tipo-pedido-editar",
          meta: {
            publicPage: false,
            authorize: ["update_type_solicitation"],
            breadcrumb: "Editar"
          },
          component: () =>
            import(
              /* webpackChunkName: "tipo-pedido-editar" */ "./views/dashboard/tipos-pedido/Form.vue"
            )
        }
      ]
    },
    {
      path: "*",
      meta: { publicPage: false },
      redirect: {
        path: "/404"
      }
    },
    {
      path: "/404",
      name: "not-found",
      meta: { publicPage: false },
      component: () =>
        import(/* webpackChunkName: "notFound" */ "./views/NotFound.vue")
    },
    {
      path: "/403",
      meta: { publicPage: false },
      name: "denied",
      component: () =>
        import(/* webpackChunkName: "accessDenied" */ `@/views/Deny.vue`)
    }
  ]
});

router.beforeEach((to, from, next) => {
  const { authorize, publicPage } = to.meta;
  const currentUser = GlobalStore.user;

  if (!publicPage && authorize) {
    if (!currentUser) {
      return next({ name: "login", query: { returnUrl: to.path } });
    }

    if (
      authorize.length &&
      !GlobalStore.functionalities.some(f => authorize.includes(f))
    ) {
      return next({ name: "denied" });
    }
  }

  next();
});
