import { print } from "graphql";
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagination } from './pagination';
import { Funcionalidade, PageFuncionalidadeInput } from '@/models/funcionalidade';

const funcionalidadePage  = require('@/query/funcionalidade/Page.graphql')
const funcionalidadeShow  = require('@/query/funcionalidade/Show.graphql')

export class FuncionalidadeAPI extends BaseAPI<Funcionalidade> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/functionalities`;
  }


  public page(
    input: PageFuncionalidadeInput
  ): AxiosObservable<Pagination<Funcionalidade>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(funcionalidadePage),
        variables: { ...input }
      })
  }

  public show(id: number): AxiosObservable<Funcionalidade> {
    return this.axios.post(`${this.graphqlUrl}`, {query: print(funcionalidadeShow), variables: { id }})
  }

  public update (id: number, body: Funcionalidade): AxiosObservable<Funcionalidade> {
    return this.axios.patch(`${this.backUrl}/${id}`, body)
  }
}
