<template>
  <v-toolbar
    color="#067B26"
    dark
    elevation="0"
    class="app-toolbar"
  >
    <v-app-bar-nav-icon @click.stop="handleDrawerToggle" />
    <v-toolbar-title class="app-toolbar-title">
      {{ app_name }}
    </v-toolbar-title>
    <app-menu />
  </v-toolbar>
</template>

<script>
  import Vue from 'vue';
  import AppMenu from './AppMenu';
  import GlobalStore from '@/store/modules/global';
  import { APP_NAME } from '@/commons/config';

  export default Vue.extend({
    name: 'AppToolbar',
    components: {
      AppMenu
    },
    data: () => ({ app_name: APP_NAME }),
    methods: {
      handleDrawerToggle() {
        GlobalStore.toggleDrawer();
      }
    }
  });
</script>
