import { Documento, PayloadUpdateStatusDocumento, PayloadUpdateInfosDocumento, PageDocumentoInput } from '@/models/documento'
import { BaseAPI } from '@/api/base.api'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import { Pagination } from './pagination';
import { print } from "graphql";

const documentoPage = require('@/query/documento/Page.graphql')
export class DocumentoAPI extends BaseAPI<Documento> {
  constructor() {
    super();
    this.backUrl = `${this.backUrl}/documents`;
  }

  public updateStatus(id: number, body: PayloadUpdateStatusDocumento): AxiosObservable<Documento> {
    return this.axios.patch(`${this.backUrl}/${id}/updateStatus`, {...body})
  }

  public updateInfos(id: number, body: PayloadUpdateInfosDocumento): AxiosObservable<Documento> {
    return this.axios.patch(`${this.backUrl}/${id}/updateInfos`, {...body})
  }

  public download(body: Documento): AxiosObservable<any> {
    return this.axios.get(`${this.backUrl}/${body.id}/download`, {
      params: { attachment: true },
      responseType: "blob"
    });
  }

  public page(
    input: PageDocumentoInput
  ): AxiosObservable<Pagination<Documento>> {
    return this.axios
      .post(`${this.graphqlUrl}`, {
        query: print(documentoPage),
        variables: { ...input }
      })
  }
}
