import Service from "@/services/pagamentos.service"
import { Pagamento, PagePagamentoInput } from '@/models/pagamento'
import { router } from '@/router'


export const pagamento = {
  namespaced: true,
  state: {
    table:  {
      header: [
        {
          text: "Pagamento ID",
          align: "start",
          sortable: true,
          value: "id"
        },
        {
          text: "Número Pedido",
          align: "start",
          sortable: true,
          value: "solicitation.id"
        },
        {
          text: "Empresa",
          align: "start",
          sortable: true,
          value: "solicitation.company.name"
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status"
        },
        {
          text: "Valor",
          align: "start",
          sortable: true,
          value: "value"
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: "actions",
          width: "100px"
        }
      ],
      content: [] as Array<Pagamento>,
      paginate: {
        input: {
          solicitationId: null as number,
          companyId: null as number,
          status: null as string,
          value: null as string
        },
        inputPage: {
          page: 1,
          size: 10,
          order: 'payment.id',
          descingOrder: false,
        },
      },
      isLoading: false,
      total: 0
    },
    detail: {
      data: {
        description: "",
      },
      isLoading: false
    }
  },
  mutations: {
    setTableIsLoading(state: any, value: boolean): void {
      state.table.isLoading = value
    },
    setTableContent(state: any, value: Pagamento[]): void {
      state.table.content = value
    },
    setTableTotal(state: any, value: number): void {
      state.table.total = value
    },
    setTablePaginate(state: any, value: PagePagamentoInput): void {
      state.table.paginate = value
    },
    setDetail(state: any, value: Pagamento): void {
      state.detail.data = value
    },
    setDetailIsLoading(state: any, value: boolean): void {
      state.detail.isLoading = value
    }

  },
  actions: {
    SEARCH({ commit, dispatch, state }: any): void {
      commit('setTableIsLoading', true)
      Service.page(state.table.paginate).subscribe(({items, totalItems})=>{
        commit('setTableContent', items)
        commit('setTableTotal', totalItems)
        commit('setTableIsLoading', false)
      }, (error)=>{
        commit('setTableIsLoading', false)
      })
    },
    CHANGE_PAGE({ commit, dispatch, state }: any, value: PagePagamentoInput): void {
      commit('setTablePaginate', value)
    },
    CLEAR({ commit }: any): void {
      commit('setTableContent', [])
      commit('setTableTotal', 0)
      commit('setTableIsLoading', false)
      commit('setTablePaginate', {
        input: {
          solicitationId: null as number,
          companyId: null as number,
          status: null as string,
          value: null as string
        },
        inputPage: {
          page: 1,
          size: 10,
          order: 'payment.id',
          descingOrder: false,
        },
      })
    },
    async SHOW({ commit, dispatch, state }: any, value: number): Promise<any> {
      commit('setDetailIsLoading', true)
      try {
        const res = await Service.show(value).toPromise()
        commit('setDetail', res)
        commit('setDetailIsLoading', false)
      } catch (error) {
        commit('setDetailIsLoading', false)
      }
    },
    CLEAR_DETAIL({ commit, dispatch, state }: any): void {
      commit('setDetail', {description: ""})
      commit('setDetailIsLoading', false)
    },
    CREATE({ commit, dispatch, state }: any, value: Pagamento) {
      commit('setDetailIsLoading', true)
      Service.create(value).subscribe((res)=>{
        commit('setDetail', res)
        commit('setDetailIsLoading', false)
        commit("global/setSnackBar", {show: true, message: "Pagamento Criado com sucesso", type: "success"}, {root: true});
        router.push({name: 'dashboard-pagamentos-list'})
      }, (error)=>{
        commit('setDetailIsLoading', false)
      })
    },
    UPDATE({ commit, dispatch, state, rootState }: any, value: Pagamento) {
      commit('setDetailIsLoading', true)
      const body: any = { status: value.status, observation: value.observation}
      Service.update_payment(value.id, body).subscribe((res)=>{
        const {detail: { data: { id }}} = rootState.pedido
        dispatch("pedido/SHOW", id, {root: true});
        commit('setDetailIsLoading', false)
        commit("global/setSnackBar", {show: true, message: "Pagamento Atualizado com sucesso", type: "success"}, {root: true});
      }, (error)=>{
        commit('setDetailIsLoading', false)
      })
    },
  },
}
